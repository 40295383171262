import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GettingStartedContent from '../../components/HelpCenter/GettingStarted/GettingStarted'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  HC_PAYMENTS_AND_WITHDRAWALS,
  HELP_CENTRE,
} from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE,
  },
  {
    title: 'Payments and Withdrawls',
    url: HC_PAYMENTS_AND_WITHDRAWALS,
  },
]

const PaymentAndWithdrawals: React.FC = () => {
  const [paymentsAndWithdrawalsFaqsSt, setPaymentsAndWithdrawalsFaqsSt] =
    useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(
      ({ paymentsAndWithdrawalsFaqs }) =>
        setPaymentsAndWithdrawalsFaqsSt(paymentsAndWithdrawalsFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <SEO
        title="Payments & Withdrawls: Get Help on Payment & Withdrawl Queries"
        description="Visit our payment and withdrawl help centre for answers to queries on wallets in Mega app, how to deposit, how to withdraw, among others. Click to read more!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(paymentsAndWithdrawalsFaqsSt)}
      />
      <GettingStartedContent
        pageIndex={1}
        faqs={paymentsAndWithdrawalsFaqsSt}
      />
    </Layout>
  )
}

export default PaymentAndWithdrawals
